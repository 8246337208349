import ResultLabels from "./ResultLabels";
import ResultMeta from "./ResultMeta";
import ResultPrice from "./ResultPrice";
import ResultStock from "./ResultStock";
import ResultEvent from "./ResultEvent";

const ResultItem = ({result}) => {
    return (
        <div className="product-card">
            <ResultLabels result={result}/>

            <a href={result.item_permalink} className="product-card__link"></a>
            <picture className="product-card__picture">
                <img
                    src={result._api_thumbnmail.guid}
                    alt={result.post_title}
                    loading="lazy"
                    className="tns-lazy-img"
                    width={result._api_thumbnmail.sizes['medium'].width}
                    height={result._api_thumbnmail.sizes['medium'].height}
                />
            </picture>
            <div className="product-card__content">
                <div className="product-card__head">
                    <div className="product-card__title">
                        {result.post_title}
                    </div>
                    {result._infos.cuvee && (
                        <div className="product-card__cuvee">
                            {result._infos.cuvee}
                        </div>
                    )}
                </div>
                <ResultMeta result={result}/>

            </div>
        </div>
    )
}
export default ResultItem;