import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context";
import ResultItem from "./Result/ResultItem";
import Loader from "./Loader";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ResultStepSVG from "./Result/ResultStepSVG";

const Results = ({formData}) => {

    const [settings, setSettings] = useState({
        dots: true,
        speed: 500,
        slidesToShow: 2,
        infinite: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 720,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                }
            },
        ]
    })
    const {results, getResults, isLoadingResults, resetForm} = useContext(AppContext);

    useEffect(() => {
        getResults(formData);
    }, [formData]);


    useEffect(() => {
        if (results) {
            const newState = {...settings};
            newState.slidesToShow = results.length > 2 ? 3 : results.length ;
            setSettings(newState)
        }
    }, [results])

    return (
        <>
            {isLoadingResults ? <Loader/> : results && results.length > 0 ? (
                <>
                    <button onClick={resetForm} className="quizz__reload">Recommencer</button>
                    <div className="quizz__final">Très bien ! Voici nos suggestions :</div>
                    {results.length > 1 ? (
                        <Slider {...settings} className="quizz__slider">
                            {results.map((result, index) => (
                                <ResultItem key={index} result={result} style={{position: "relative"}}/>
                            ))}
                        </Slider>
                    ) : (
                        <div className="quizz_results-list">
                            {results.map((result, index) => (
                                <ResultItem key={index} result={result} style={{position: "relative"}}/>
                            ))}
                        </div>
                    )}

                </>
            ) : (
                <>
                    <button onClick={resetForm} className="quizz__reload">Recommencer</button>
                    <p className="quizz__no-result">Il n' y a pas de résultats</p>
                </>
            )}
        </>
    )
}

export default Results;