
import React from "react";

const ResultMeta = ({result}) => {
    return(
        <div className="product-card__meta">
            {result._infos.attributes.pa_appellation && (
                <div
                    className="product-card__appellation">{result._infos.attributes.pa_appellation[0].name}</div>
            )}
            {result._infos.attributes.pa_millesime && (
                <div className="product-card__millesime">{result._infos.attributes.pa_millesime[0].name}</div>
            )}

        </div>
    )
}
export default ResultMeta;