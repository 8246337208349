import React, {useEffect, useState} from "react";
import FormContainer from "./components/FormContainer";
import {AppContext} from './context';

const App = () => {
    const [domain, setDomain] = useState(null);
    const [formFields, setFormFields] = useState(null);
    const [isStarted, setIsStarted] = useState(false);
    const [results, setResults] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingResults, setIsLoadingResults] = useState(false);
    const [currentStepIndex, setCurrentStepIndex] = useState(0);

    useEffect(() => {
        const container = document.getElementById('quizz__module');
        if (container) {
            setDomain(container.dataset.domain);
        }
    }, []);


    const resetForm = () => {
        setResults([]);
        setCurrentStepIndex(0);
    }

    /**
     * Get survey
     *
     * @returns {Promise<null>}
     */
    const fetchFormFields = async () => {
        setIsLoading(true);
        let data = null;

        if (domain) {
            const response = await fetch(`${domain}/${process.env.REACT_APP_FORM_PATH}`);
            data = await response.json();

            setFormFields(data);
        }

        setIsLoading(false);
        return data
    }

    /**
     * Get results of survey
     *
     * @param terms
     * @returns {Promise<void>}
     */
    const getResults = async (terms) => {
        setIsLoadingResults(true);
        const query_string = terms.join(',');
        const response = await fetch(`${domain}/${process.env.REACT_APP_FORM_PATH}/choices=${query_string}`);
        const data = await response.json();

        setResults(data);
        setIsLoadingResults(false);
    }

    const handleClick = () => {
        setIsStarted(prevProps => !prevProps);
    }

    return (
        <AppContext.Provider value={{getResults, results, isStarted, setIsStarted, isLoadingResults, resetForm, formFields, currentStepIndex, setCurrentStepIndex, isLoading, fetchFormFields}}>
        <div className="App">
            <button className="quizz__button" onClick={handleClick}>Aidez-moi à choisir ! 🤔</button>
            { isStarted && <FormContainer />}
        </div>
        </AppContext.Provider>
    );
}

export default App;
