import React, {useEffect, useState} from "react";

const Step = ({stepIndex, currentStepIndex, issue, handleChoice, stepIsCompleted, formData, setCurrentStepIndex}) => {

    const [answers, setAnswers] = useState(null);
    const [displayIcon, setDisplayIcon] = useState(false);

    const updateAnswers = () => {
        //Loop on conditions
        for (let i = 0; i < issue.conditionnal_answers.length; i++) {
            let condition = issue.conditionnal_answers[i];
            let issueIndex = issue.issue_number - 1;

            // If there is a condition, we get the result of the form for the concerned answer
            if (condition) {
                const selectedAnswer = formData[issueIndex];

                if (selectedAnswer) {

                    for (let j = 0; j < condition.selected_answers.length; j++) {
                        if (condition.selected_answers[j].value === selectedAnswer) {
                            setAnswers(condition.suggested_answers)
                            break;
                        }

                    }
                }

            }

        }
    }

    useEffect(() => {
        if (issue.display_conditionnal_answers === true) {
            updateAnswers();
        } else {
            setAnswers(issue.answers);
        }
    }, [formData]);

    useEffect(() => {
        setDisplayIcon(issue.quizz_button_style);
    }, [issue])

    return (
        <>
            {stepIndex === currentStepIndex && (
                <div className={`quizz__field ${issue.quizz_items_per_col} step-${stepIndex + 1}`}>
                    {stepIndex > 0 &&
                        <button className="quizz__back"
                                onClick={() => setCurrentStepIndex(prevProps => stepIndex - 1)}>Retour</button>}
                    <div className="quizz__picture">
                        <img src={issue.quizz_picture.url} alt={issue.quizz_issue}/>
                    </div>
                    <div className="quizz__picture-right">
                        <img src={issue.quizz_picture_right.url} alt={issue.quizz_issue}/>
                    </div>
                    <div className="quizz__issue">
                        {issue.quizz_issue}
                    </div>
                    <div className={`quizz__answers-container ${displayIcon ? 'with-icon' : 'no-icon'} `}>
                        {answers && answers.map((answer, index) => (
                            <button
                                key={index}
                                data-term={answer.value}
                                className={`quizz__answers ${answer.value === formData[stepIndex] ? ' is-active ' : ' '}  ${displayIcon ? 'with-icon' : 'no-icon'}`}
                                onClick={() => handleChoice(answer.value, stepIndex)}>

                                {displayIcon && answer.icon &&
                                    <span className="quizz__icon"><img src={answer.icon.url}
                                                                       alt={answer.label}/></span>}
                                {answer.label}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </>
    )
}

export default Step;