import React from "react";

const ResultLabels = ({result}) => {
    return (
        <div className="product-labels">
            {result.sale_price && (
                <div className="product-labels__item">
                    <svg xmlns="http://www.w3.org/2000/svg" width="47.057" height="37.111"
                         viewBox="0 0 47.057 37.111">
                        <g id="Icon_Mode_Detail_-_Promo" data-name="Icon Mode Detail - Promo"
                           transform="translate(-5.715 -17.608)">
                            <path id="Tracé_6503" data-name="Tracé 6503"
                                  d="M32.151,65.1a32.087,32.087,0,0,0,3.8,7.349,2.423,2.423,0,0,1-1.094,3.492h0c-1.3.625-2.5.313-3.492-1.147A38.368,38.368,0,0,1,27.2,66.247"
                                  transform="translate(-9.899 -22.355)" fill="#6f1c75"/>
                            <path id="Tracé_6504" data-name="Tracé 6504" d="M65,35.781a3.565,3.565,0,1,1,1.876,6.88"
                                  transform="translate(-27.998 -8.254)" fill="#fff"/>
                            <g id="Groupe_2883" data-name="Groupe 2883" transform="translate(22.356 18.412)">
                                <path id="Tracé_6505" data-name="Tracé 6505"
                                      d="M38.516,34.875,36.9,28.986c1.824-.99,6.046-3.492,10.267-10.163a.862.862,0,0,1,1.564.208L55.4,43.162a.844.844,0,0,1-1.2.99c-7.088-3.6-11.987-3.544-14.072-3.44l-1.616-5.837"
                                      transform="translate(-36.9 -18.412)" fill="#ffb400"/>
                            </g>
                            <path id="Tracé_6506" data-name="Tracé 6506"
                                  d="M23.332,53.526l1.407-.417a5.643,5.643,0,0,0,3.909-6.88l-.625-2.293a5.643,5.643,0,0,0-6.88-3.909l-1.407.417a5.643,5.643,0,0,0-3.909,6.88l.625,2.293A5.6,5.6,0,0,0,23.332,53.526Z"
                                  transform="translate(-4.364 -10.259)" fill="#6f1c75"/>
                            <path id="Tracé_6507" data-name="Tracé 6507"
                                  d="M10.63,48.3l-1.251.365a3.88,3.88,0,0,0-2.71,4.795l.261.99a3.88,3.88,0,0,0,4.795,2.71l1.251-.365Z"
                                  transform="translate(0 -14.311)" fill="#ffb400"/>
                            <path id="Tracé_6508" data-name="Tracé 6508"
                                  d="M32.151,65.1a32.087,32.087,0,0,0,3.8,7.349,2.423,2.423,0,0,1-1.094,3.492h0c-1.3.625-2.5.313-3.492-1.147A38.368,38.368,0,0,1,27.2,66.247"
                                  transform="translate(-9.899 -22.355)" fill="none" stroke="#000"
                                  stroke-linecap="round"
                                  stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.6"/>
                            <path id="Tracé_6509" data-name="Tracé 6509" d="M65,35.781a3.565,3.565,0,1,1,1.876,6.88"
                                  transform="translate(-27.998 -8.254)" fill="none" stroke="#000"
                                  stroke-linecap="round"
                                  stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.6"/>
                            <g id="Groupe_2884" data-name="Groupe 2884" transform="translate(22.356 18.412)">
                                <path id="Tracé_6510" data-name="Tracé 6510"
                                      d="M38.516,34.875,36.9,28.986c1.824-.99,6.046-3.492,10.267-10.163a.862.862,0,0,1,1.564.208L55.4,43.162a.844.844,0,0,1-1.2.99c-7.088-3.6-11.987-3.544-14.072-3.44l-1.616-5.837"
                                      transform="translate(-36.9 -18.412)" fill="none" stroke="#000"
                                      stroke-linecap="round"
                                      stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.6"/>
                            </g>
                            <path id="Tracé_6511" data-name="Tracé 6511"
                                  d="M23.332,53.526l1.407-.417a5.643,5.643,0,0,0,3.909-6.88l-.625-2.293a5.643,5.643,0,0,0-6.88-3.909l-1.407.417a5.643,5.643,0,0,0-3.909,6.88l.625,2.293A5.6,5.6,0,0,0,23.332,53.526Z"
                                  transform="translate(-4.364 -10.259)" fill="none" stroke="#000"
                                  stroke-linecap="round"
                                  stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.6"/>
                            <line id="Ligne_134" data-name="Ligne 134" x2="5.212" y2="0.834"
                                  transform="translate(46.643 35.396)" fill="none" stroke="#000"
                                  stroke-linecap="round"
                                  stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.6"/>
                            <line id="Ligne_135" data-name="Ligne 135" y1="0.886" x2="3.127"
                                  transform="translate(46.643 27.579)" fill="none" stroke="#000"
                                  stroke-linecap="round"
                                  stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.6"/>
                            <line id="Ligne_136" data-name="Ligne 136" y1="3.388" x2="4.743"
                                  transform="translate(43.099 19.135)" fill="none" stroke="#000"
                                  stroke-linecap="round"
                                  stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.6"/>
                            <path id="Tracé_6512" data-name="Tracé 6512"
                                  d="M10.63,48.3l-1.251.365a3.88,3.88,0,0,0-2.71,4.795l.261.99a3.88,3.88,0,0,0,4.795,2.71l1.251-.365Z"
                                  transform="translate(0 -14.311)" fill="none" stroke="#000" stroke-linecap="round"
                                  stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.6"/>
                        </g>
                    </svg>
                    <span>En promo</span>
                </div>
            )}


            {result._taxonomies.product_highlighting && result._taxonomies.product_highlighting.length > 0 && result._taxonomies.product_highlighting.map((item, index) => (
                <div className="product-labels__item" key={index}>
                    {item?.label?.icon && (<img src={item.label.icon.url} alt={item.label.icon.name}
                                                loading="lazy"/>)
                    }
                    <span>{item.label.label}</span>
                </div>
            ))}

            {result.attributes.pa_engagement && result.attributes.pa_engagement.length > 0 && result.attributes.pa_engagement.map((item, index) => (
                <div className="product-labels__item" key={index}>
                    {item?.label?.icon && (<img src={item.label.icon.url} alt={item.label.icon.name}
                                                loading="lazy"/>)
                    }
                    <span>{item?.label?.label}</span>
                </div>
            ))}

        </div>
    )
}
export default ResultLabels;