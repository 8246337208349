import React, {useContext, useEffect, useState} from "react";
import Form from "./Form";
import {AppContext} from "../context";
import Loader from "./Loader";

const FormContainer = () => {

    const {setIsStarted, isLoading, fetchFormFields, resetForm, formFields} = useContext(AppContext);

    const handleLoad = async () => {
        if(formFields !== null){
            return;
        }
        const data = await fetchFormFields();

    }

    useEffect(() => {
        handleLoad();
    }, [])

    const handleClose = (e) => {
        resetForm();
        setIsStarted(false);
    }

    return (
        <div className="quizz__overlay" onClick={handleClose}>
            {isLoading || !formFields ? <Loader/> : (
                <div className="quizz__container" onClick={e => e.stopPropagation()}>
                    <button className="quizz__close-btn" onClick={() => {setIsStarted(false); resetForm();}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30.121" height="30.121"
                             viewBox="0 0 30.121 30.121">
                            <g id="Icon_Close" transform="translate(1.061 1.061)">
                                <line id="Ligne_43" x2="28" y2="28" fill="none" stroke="#fff" strokeLinecap="round"
                                      strokeMiterlimit="10" strokeWidth="1.5"/>
                                <line id="Ligne_44" x1="28" y2="28" fill="none" stroke="#fff" strokeLinecap="round"
                                      strokeMiterlimit="10" strokeWidth="1.5"/>
                            </g>
                        </svg>
                    </button>
                    <Form formFields={formFields}/>
                </div>
            )}


        </div>
    )
}

export default FormContainer;