import React, {useContext, useState} from "react";
import Step from "./Step";
import Results from "./Results";
import {AppContext} from "../context";

const Form = ({formFields}) => {

    const {currentStepIndex, setCurrentStepIndex} = useContext(AppContext);

    const [formData, setFormData] = useState([]);
    const {survey} = formFields;

    /**
     * Validate if all steps are valids
     * @returns {boolean}
     */
    const validateFormBeforeSubmit = () => {
        if (
            currentStepIndex === survey.length &&
            formData.length === survey.length
        ) {
            return true
        }
        return false;
    }

    /**
     * Handle Form choice
     *
     * @param value
     */
    const handleChoice = (value, step) => {
        const newState = [...formData];
        newState[step] = value;
        setFormData(oldState => newState);
        if (step === currentStepIndex) {
            setCurrentStepIndex(oldState => oldState + 1);
        }

    }

    return (
        <>
            {validateFormBeforeSubmit() ? (
                <div className="quizz__results">
                    <Results formData={formData}/>
                </div>

            ) : (
                <div className="quizz__form">
                    {formFields && formFields.survey.map((field, stepIndex) => (
                        <Step
                            key={stepIndex}
                            stepIndex={stepIndex}
                            currentStepIndex={currentStepIndex}
                            issue={field}
                            handleChoice={handleChoice}
                            formData={formData}
                            setCurrentStepIndex={setCurrentStepIndex}
                            stepIsCompleted={formData[stepIndex] !== undefined}
                        />
                    ))}
                </div>
            )}

        </>
    )
}

export default Form;